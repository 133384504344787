@tailwind base;
@tailwind components;
@tailwind utilities;


@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Plus+Jakarta+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400&family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;600;700;800;900&display=swap");

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Red Hat Display" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer components {
  .btn-primary {
    @apply px-5  py-1.5 text-sm bg-primary text-white font-[500] rounded-lg border-[1px] border-primary;
  }
 
  .btn-secondary {
    @apply px-5  py-1.5 text-sm text-gray-500 font-[500] rounded-lg border-[1px] border-gray-400;
  }

  .title {
    @apply text-2xl font-[600] text-[#101828] ease-in duration-300;
  }
}
html {
  font-family: "Red Hat Display";
}
 .ant-btn-primary {
   @apply text-sm bg-[#3795F7] text-white font-[500] rounded-lg border-[1px] border-[#3795F7] hover:bg-white;
 }
 .ant-modal-title {
  font-size: 1.5rem !important;
 }
